exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-registrieren-tsx": () => import("./../../../src/pages/registrieren.tsx" /* webpackChunkName: "component---src-pages-registrieren-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/productPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-register-form-tsx": () => import("./../../../src/templates/registerForm.tsx" /* webpackChunkName: "component---src-templates-register-form-tsx" */),
  "component---src-templates-state-tsx": () => import("./../../../src/templates/state.tsx" /* webpackChunkName: "component---src-templates-state-tsx" */),
  "component---src-templates-supportive-tsx": () => import("./../../../src/templates/supportive.tsx" /* webpackChunkName: "component---src-templates-supportive-tsx" */)
}

